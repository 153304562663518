import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import L from 'leaflet'
import type { FeatureCollection } from 'geojson'
import {
  defaultPollingStationLayerStyle,
  highlightLayerStyle,
  selectedLayerStyle,
  unknown
} from '@/modules/map/utils/map-layer-styles'

import { trimAndLowercase } from '../../core/utils/string.util'
import MapService from '../services/map-api.service'
import { useVoterStore } from '@/modules/voter/stores/voter.store'
import type { LegendItem } from '../models/legend-item.model'
import PollingCenterApiService from '@/modules/polling-center/services/polling-center-api.service'
import {
  emptyPollingCenter,
  type PollingCenter
} from '@/modules/polling-center/models/polling-center.model'
import { usePollingCenterFormStore } from '@/modules/map/stores/polling-center-form.store'

const mapService = new MapService()
const pollingStationService = new PollingCenterApiService()

export const useVoronoiConstituencyMapStore = defineStore('voronoiConstituencyMap', () => {
  const mVoterStore = useVoterStore()
  const mPollingCenterFormStore = usePollingCenterFormStore()

  const mIsLoading = ref(false)

  const mConstituencyGeoJson = ref<FeatureCollection | null>(null)
  const mPollingCentersCount = ref<
    {
      constituency: string
      polling_station_count: string | number
      polling_station_found_count: string | number
      region: string
    }[]
  >([])

  const mPollingCenters = ref<PollingCenter[]>([])
  const mSelectedPollingCenters = ref<PollingCenter[]>([])

  const mMap = ref<L.Map | null>(null)
  const mConstituencyBounds = ref<L.LatLngBounds | null | undefined>(null)

  const isLegendVisible = ref<boolean>(true)

  // const percentageThreshold = [
  //   { threshold: 30, color: '#800026' },
  //   { threshold: 25, color: '#BD0026' },
  //   { threshold: 15, color: '#E31A1C' },
  //   { threshold: 12.5, color: '#FC4E2A' },
  //   { threshold: 10, color: '#FD8D3C' },
  //   { threshold: 7.5, color: '#FEB24C' },
  //   { threshold: 1, color: '#FED976' },
  //   { threshold: 0, color: '#fecc5c' }
  // ]

  function resetStore() {
    mSelectedPollingCenters.value = []
    // setActiveLayer()
  }

  const layers = {
    constituency: null as L.GeoJSON | null
  }

  watch(mSelectedPollingCenters, () => {
    updateLayers()
  })

  const mLegendItems = ref<LegendItem[]>([])
  // const mLegendItems = computed(() => {
  //   const legendItems: LegendItem[] = [
  //     // { id: 8, label: '', colorOne: '#FED976', value: '0% - 7.5%' },
  //     // { id: 7, label: '', colorOne: '#FEB24C', value: '7.5% - 10%' },
  //     // { id: 6, label: '', colorOne: '#FD8D3C', value: '10% - 12.5%' },
  //     // { id: 5, label: '', colorOne: '#FC4E2A', value: '12.5% - 15%' },
  //     // { id: 4, label: '', colorOne: '#E31A1C', value: '15% - 20%' },
  //     // { id: 2, label: '', colorOne: '#BD0026', value: '20% - 25%' },
  //     // { id: 1, label: '', colorOne: '#800026', value: '25% - 30% +' }
  //   ]
  //
  //   return legendItems
  // })

  const defaultMapView = {
    center: [6.6954967, -1.6194634],
    zoom: 10
  }

  function initializeMap(mapContainer: HTMLElement) {
    if (mapContainer) {
      mMap.value = L.map(mapContainer, {
        // center: defaultMapView.center as L.LatLngExpression,
        zoom: defaultMapView.zoom,
        zoomAnimation: true,
        zoomSnap: 0.25,
        zoomDelta: 0.25,
        wheelDebounceTime: 40
      })

      // Custom icon
      const customIcon = L.icon({
        iconUrl: '/images/pin.png', // Update with your icon path
        iconSize: [38, 38], // size of the icon
        iconAnchor: [22, 38], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -38] // point from which the popup should open relative to the iconAnchor
      })
      for (const center of mPollingCenters.value) {
        if (center.latitude && center.longitude) {
          L.tooltip({
            permanent: true, // Keeps the tooltip always visible
            direction: 'center', // Centered on the map
            className: 'custom-tooltip' // Optional: Add a custom CSS class
          })
            .setContent(`${center.code.toUpperCase()}`)
            .setLatLng([center.latitude, center.longitude]) // Position of the tooltip
          // .addTo(mMap.value as L.Map);

          const marker = L.marker([center.latitude, center.longitude], { icon: customIcon }).addTo(
            mMap.value as L.Map
          )
          // marker.bindTooltip('Welcome to Accra!').openTooltip();
          marker.bindPopup(center.name)
          marker.on('click', () => {
            const { lat, lng } = marker.getLatLng()
            const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`
            window.open(googleMapsUrl, '_blank')
          })
        }
      }

      // setActiveLayer()
    }
  }

  function initializeLayers() {
    if (!mMap.value) return

    layers.constituency = L.geoJSON(mConstituencyGeoJson.value, {
      style: defaultPollingStationLayerStyle,
      onEachFeature: onEachPollingCenter
    })

    setActiveLayer()
  }

  const getMapCenter = () => {
    if (mMap.value) {
      const center = mMap.value.getCenter()
      console.log('Map Center:', center)
      return center // Returns a LatLng object with lat and lng properties
    }
    return null
  }

  const recenterMap = (lat: number, lng: number, zoom?: number) => {
    if (mMap.value) {
      mMap.value.setView([lat, lng], zoom || mMap.value.getZoom()) // Use current zoom if none provided
    }
  }

  function setActiveLayer() {
    if (!mMap.value) return

    layers.constituency?.addTo(mMap.value as L.Map)

    if (!mConstituencyBounds.value) {
      mConstituencyBounds.value = layers.constituency?.getBounds() ?? null
      if (mConstituencyBounds.value) {
        mMap.value.fitBounds(mConstituencyBounds.value)
      }
      console.log('mConstituencyBounds:', mConstituencyBounds.value)
    } else {
      mMap.value.fitBounds(mConstituencyBounds.value)
    }

    updateLayers()

    const bounds = layers.constituency?.getBounds()
    const center = bounds?.getCenter()
    console.log('Voronoi::Constituency Center:', center)

    mMap.value.fitBounds(bounds!)
    // recenterMap(center!.lat, center!.lng)
    const refresh = localStorage.getItem('voronoi_refrash') ?? '1'

    // if (refresh === '1') {
    //   window.location.reload();
    //   localStorage.setItem('voronoi_refrash', '0')
    // }
  }

  // function getFillColor(code: string): string {
  //   // Get the appropriate election result based on the current level
  //   const growth = mVoterStore.getRegisteredVotersPollingStation(code)
  //
  //   if (!growth) {
  //     console.log('fill_color::else: ', code)
  //     return unknown // Return default color if no results are available
  //   }
  //
  //   const percentage = growth.percentage
  //
  //   if (percentage) {
  //     return getColor(percentage)
  //   } else {
  //     return unknown
  //   }
  // }
  //
  // function getColor(percentage: number): string {
  //   const colors = percentageThreshold
  //   return colors.find((c) => percentage >= c.threshold)?.color ?? '#fecc5c'
  // }

  function updateLayers() {
    if (layers.constituency) {
      layers.constituency.eachLayer((layer: L.Layer) => {
        // const layerS = layer as L.Path
        const pollingCenterCode = (layer as any).feature.properties.code
        const pollingCenterColour = (layer as any).feature.properties.colour
        const pollingColourCodeNormalized = trimAndLowercase(pollingCenterCode)

        let fillColor: string | undefined = unknown
        let weight: number = defaultPollingStationLayerStyle.weight

        if (mSelectedPollingCenters.value.length >= 1) {
          mSelectedPollingCenters.value.forEach((c) => {
            if (trimAndLowercase(c.code) === pollingColourCodeNormalized) {
              fillColor = c.colour
              weight = selectedLayerStyle.weight
            }
          })
        } else {
          fillColor = pollingCenterColour
          weight = defaultPollingStationLayerStyle.weight
        }

        if ('setStyle' in layer) {
          const layerS = layer as L.Path
          const currentStyle = layerS.options
          layerS.setStyle({
            ...currentStyle,
            fillColor: fillColor,
            weight: weight
          })
        } else {
          console.error('Layer does not support setStyle')
        }
      })
    }
  }

  const fitBounds = () => {
    if (mMap.value && mConstituencyBounds.value) {
      console.log('fir_bounds')
      zoomToFeature(mConstituencyBounds.value)
    }
  }

  function zoomToFeature(bounds: L.LatLngBounds) {
    mMap.value?.flyToBounds(bounds, {
      duration: 0.5, // Duration of animation in seconds
      easeLinearity: 0.25 // Smooth out the animation
    })
  }

  function onEachPollingCenter(feature: any, layer: L.Layer) {
    const pollingStationName = feature.properties.name

    const layerS = layer as L.Path

    if ('setStyle' in layer) {
      // const layerStyle = layer as L.Path;
      layerS.setStyle({
        ...defaultPollingStationLayerStyle
      })
      layerS
        .bindTooltip(`<h5>${pollingStationName.toUpperCase()}</h5>`, { direction: 'top' })
        .openTooltip()
    } else {
      console.error('Layer does not support setStyle')
    }
    // layerS.setStyle({
    //   ...defaultPollingStationLayerStyle,
    // })

    layer.on({
      click: (e) => {
        const clickedLayer = e.target as L.Layer

        const pollingStationNameNormalized = trimAndLowercase(pollingStationName)

        const pollingCenter = mPollingCenters.value.find(
          (c) => trimAndLowercase(c.name) === pollingStationNameNormalized
        )

        if (pollingCenter) {
          const constituencyAlreadySelected = mSelectedPollingCenters.value.some(
            (c) => trimAndLowercase(c.name) === pollingStationNameNormalized
          )

          if (!constituencyAlreadySelected) {
            mPollingCenterFormStore.editPollingCenter(pollingCenter as PollingCenter)
            mSelectedPollingCenters.value = [...mSelectedPollingCenters.value, pollingCenter]
          } else {
            mSelectedPollingCenters.value = mSelectedPollingCenters.value.filter(
              (c) => trimAndLowercase(c.name) !== pollingStationNameNormalized
            )
          }
        }
        // zoomToFeature(e.target.getBounds())
      },
      mouseover: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        if (!mSelectedPollingCenters.value.includes(pollingStationName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            weight: 4,
            color: '#666',
            fillOpacity: highlightLayerStyle.fillOpacity
          }
          layer.setStyle(newStyle)
        }

        layer
          .bindTooltip(`<h5>${pollingStationName.toUpperCase()}</h5>`, { direction: 'top' })
          .openTooltip()
      },
      mouseout: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        if (!mSelectedPollingCenters.value.includes(pollingStationName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            weight: defaultPollingStationLayerStyle.weight,
            color: defaultPollingStationLayerStyle.color,
            fillOpacity: defaultPollingStationLayerStyle.fillOpacity
          }
          layer.setStyle(newStyle)
        }
      }
    })
  }

  function toggleLegend() {
    isLegendVisible.value = !isLegendVisible.value
  }

  function showCentersOnMap() {
    const baseUrl = 'https://www.google.com/maps/dir/'
    const centersPaths = mPollingCenters.value
      .map((coord) => `${coord.latitude},${coord.longitude}`)
      .join('/')

    const finalUrl = `${baseUrl}${centersPaths}`
    window.open(finalUrl, '_blank')
  }

  const pollingStationsCountSummary = (constituency: string) =>
    computed(() => {
      const stations = mPollingCentersCount.value.filter(
        (station) => trimAndLowercase(station.constituency) === trimAndLowercase(constituency)
      )

      const summary = stations.reduce(
        (acc, s) => {
          const count = s.polling_station_count ?? 0
          const foundCount = s.polling_station_found_count ?? 0

          return {
            ...acc,
            count: Number(acc.count) + Number(count),
            foundCount: Number(acc.foundCount) + Number(foundCount)
          }
        },
        {
          count: 0,
          foundCount: 0
        }
      )

      return `${summary.foundCount} of ${summary.count} Polling Stations Found`
    })

  const getPollingCentersCount = async (region: string) => {
    const result = await pollingStationService.getPollingCentersCount(region)

    result.fold(
      (value) => {
        mPollingCentersCount.value = value as any[]
      },
      (error) => console.log(error)
    )
  }

  const getConstituencyByRegionShapeFile = async (region: string, constituency: string) => {
    const result = await mapService.getPollingStationShapeFile(region, constituency)

    result.fold(
      (value) => {
        mConstituencyGeoJson.value = value as FeatureCollection
        console.log('constituency shape file set')
        mPollingCenters.value = extractPollingCenters(mConstituencyGeoJson.value)
      },
      (error) => console.log(error)
    )
  }

  const getConstituencyShapeFileByConstituency = async (constituency: string) => {
    mIsLoading.value = true
    const result = await mapService.getPollingStationShapeFileByConstituency(
      'ghana',
      constituency,
      '2024'
    )

    result.fold(
      (value) => {
        mConstituencyGeoJson.value = value.voronoi as FeatureCollection
        console.log('constituency shape file set')
        mLegendItems.value = extractLegends(value.colour_map)
        mPollingCenters.value = extractPollingCenters(mConstituencyGeoJson.value)

        mIsLoading.value = false
      },
      (error) => {
        mIsLoading.value = false
        console.error(error)
      }
    )
  }

  function extractLegends(colourMap?: any[] | null): LegendItem[] {
    if (colourMap) {
      const legends = colourMap.map((colour) => {
        const valueFrom = colour.range[0] ?? `< ${colour.range[1]}`
        const valueTo = colour.range[1] ?? `> ${colour.range[0]}`
        return {
          id: 8,
          label: '',
          colorOne: colour.colour,
          value: `${valueFrom} to ${valueTo}`
        } as LegendItem
      })

      return legends
    }
    return []
  }

  function extractPollingCenters(geoJson?: FeatureCollection | null): PollingCenter[] {
    if (geoJson) {
      const pollingCenters = geoJson.features
        .map((feature: any) => [
          feature.properties?.code.trim(),
          feature.properties?.name.trim(),
          feature.properties?.latitude,
          feature.properties?.longitude,
          feature.properties?.colour,
          feature.properties?.image_url
        ])
        .filter((region: any) => region !== undefined) // Filter out undefined regions
      return Array.from(new Set(pollingCenters))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort the regions by name
        .map(([code, name, latitude, longitude, colour, imageUrl], index) => ({
          ...emptyPollingCenter,
          id: index + 1,
          code: code,
          name: name,
          latitude: latitude,
          longitude: longitude,
          colour: colour,
          imageUrl: imageUrl
        })) // Remove duplicates with incremental id
    }
    return []
  }

  return {
    pollingCenters: mPollingCenters,
    selectedPollingCenters: mSelectedPollingCenters,
    isLegendVisible,
    legendItems: mLegendItems,
    pollingCentersCount: mPollingCentersCount,
    isLoading: mIsLoading,
    pollingStationsCountSummary,
    getConstituencyByRegionShapeFile,
    getConstituencyShapeFileByConstituency,
    getPollingCentersCount,
    fitBounds,
    initializeMap,
    initializeLayers,
    // setActiveLayer,
    toggleLegend,
    showCentersOnMap,
    resetStore
  }
})
