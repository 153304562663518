import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useForm } from 'vee-validate'
import { object, string, number } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'

import { emptyPollingCenter, type PollingCenter } from '../../polling-center/models/polling-center.model'

// const baseStore = useBaseStore()
// const apiService = new PollingCenterService()

const schema = toTypedSchema(
  object({
    region: string().required().min(4).label('region'),
    constituency: string().required().min(4).label('constituency'),
    code: string().required().min(4).label('code'),
    name: string().required().min(4).label('Name'),
    latitude: number().required().label('latitude'),
    longitude: number().required().label('longitude'),
  })
)

export const usePollingCenterFormStore = defineStore(
  'pollingStationForm',
  () => {
    const { defineComponentBinds, setFieldValue, handleSubmit, errors } = useForm({
      validationSchema: schema
    })

    const region = defineComponentBinds('region')
    const constituency = defineComponentBinds('constituency')
    const code = defineComponentBinds('code')
    const name = defineComponentBinds('name')
    const latitude = defineComponentBinds('latitude')
    const longitude = defineComponentBinds('longitude')

    const showPollingCenterDialog = ref(false)
    const isSubmitting = ref(false)


    const pollingCenter = ref<PollingCenter>(emptyPollingCenter)
    const isEditing = ref<boolean>(false)

    const createPollingCenter = () => {
      isEditing.value = false
      pollingCenter.value = emptyPollingCenter
      showPollingCenterDialog.value = true
    }

    const editPollingCenter = (editPollingCenter: PollingCenter) => {
      isEditing.value = true
      pollingCenter.value = editPollingCenter

      setFieldValue('region', editPollingCenter.region)
      setFieldValue('constituency', editPollingCenter.constituency)
      setFieldValue('code', editPollingCenter.code)
      setFieldValue('name', editPollingCenter.name)
      setFieldValue('latitude', editPollingCenter.latitude)
      setFieldValue('longitude', editPollingCenter.longitude)
      setFieldValue('longitude', editPollingCenter.longitude)

      showPollingCenterDialog.value = true
    }

    const onSavePollingCenter = handleSubmit((values) => {
      savePollingCenter();
    })

    const savePollingCenter = async () => {
      isSubmitting.value = true;

      // const result = await apiService.postOrPatchPollingCenter(pollingCenter.value, !isEditing.value ? 'post' : 'patch');

      // result.fold(
      //   (value) => {
      //     isSubmitting.value = false;
      //     showPollingCenterDialog.value = false
      //     baseStore.showToast({
      //       severity: 'success',
      //       summary: PollingCenterUiConstants.TOAST_SUMMARY,
      //       detail: `PollingCenter with Id ${value} Saved`,
      //       life: 3000
      //     });
      //     isEditing.value = false;
      //     usePollingCenterIndexStore().getPollingCenters()
      //   },
      //   (error) => {
      //     isSubmitting.value = false;
      //     baseStore.showToast({
      //       severity: 'error',
      //       summary: PollingCenterUiConstants.TOAST_SUMMARY,
      //       detail: error.message,
      //       life: 3000
      //     });
      //     isEditing.value = false;
      //   },
      // );
    };

    return {
      errors,
      region,
      constituency,
      code,
      name,
      latitude,
      longitude,

      pollingCenter,
      showPollingCenterDialog,
      isSubmitting,
      createPollingCenter,
      editPollingCenter,
      onSavePollingCenter
    }
  }
)
