<script setup lang="ts">
import { usePollingCenterFormStore } from '../stores/polling-center-form.store'

const formStore = usePollingCenterFormStore()
</script>

<template>
  <Dialog
    v-model:visible="formStore.showPollingCenterDialog"
    :style="{ width: '600px' }"
    header="Polling Center Details"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-4">
        <label for="code">Polling Center Code</label>
        <InputText
          id="code"
          type="text"
          v-bind="formStore.code"
          v-model="formStore.pollingCenter.code"
          required="true"
          :class="{ 'p-invalid': formStore.errors.code }"
        />
        <small id="code-help" class="p-error">
          {{ formStore.errors.code }}
        </small>
      </div>
      <div class="field col-12 md:col-8">
        <label for="name">Polling Center Name</label>
        <InputText
          id="name"
          type="text"
          v-bind="formStore.name"
          v-model="formStore.pollingCenter.name"
          required="true"
          :class="{ 'p-invalid': formStore.errors.name }"
        />
        <small id="name-help" class="p-error">
          {{ formStore.errors.name }}
        </small>
      </div>
      <div class="field col-12 md:col-6">
        <label for="latitude">Latitude</label>
        <InputNumber
          id="latitude"
          type="text"
          v-bind="formStore.latitude"
          v-model="formStore.pollingCenter.latitude"
          required="true"
          :class="{ 'p-invalid': formStore.errors.latitude }"
        />
        <small id="latitude-help" class="p-error">
          {{ formStore.errors.latitude }}
        </small>
      </div>
      <div class="field col-12 md:col-6">
        <label for="longitude">Longitude</label>
        <InputNumber
          id="longitude"
          type="text"
          v-bind="formStore.longitude"
          v-model="formStore.pollingCenter.longitude"
          required="true"
          :class="{ 'p-invalid': formStore.errors.longitude }"
        />
        <small id="longitude-help" class="p-error">
          {{ formStore.errors.longitude }}
        </small>
      </div>

      <div class="card flex justify-center">
        <Image :src="formStore.pollingCenter.imageUrl" alt="Image" width="500" preview />
      </div>
    </div>

    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        class="p-button-text"
        @click="formStore.showPollingCenterDialog = false"
      />
    </template>
  </Dialog>
</template>
